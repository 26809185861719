import { ColorAreaFormatted } from "contexts/settings/ColorAreas/colorAreasSettings-types"
import { useColorAreaSettings } from "hooks/useColorAreaSettings"

export type ColorArea =
  | "image-banner"
  | "text-banner"
  | "nav-tiles"
  | "side-nav"

const DEFAULT_OVERRIDES = {
  colorOverride: "",
  backgroundColorOverride: "",
  backgroundOverlayBeforeOverride: "",
  backgroundOverlayAfterOverride: "",
  backgroundColorGradientOverride: "",
  fillColorOverride: "",
}

export const useColorsOverride = (colorArea: ColorArea) => {
  let colorAreaToBeOverriten: "" | ColorAreaFormatted = ""

  switch (colorArea) {
    case "image-banner":
      const { imageBannerColorOverlay } = useColorAreaSettings()
      colorAreaToBeOverriten = imageBannerColorOverlay
    case "text-banner":
      const { textBannerBackgroundColor } = useColorAreaSettings()
      colorAreaToBeOverriten = textBannerBackgroundColor

    case "nav-tiles":
      const { navTileColorOverlay } = useColorAreaSettings()
      colorAreaToBeOverriten = navTileColorOverlay

    case "side-nav":
    default:
      const { sideNavBackgroundColor } = useColorAreaSettings()
      colorAreaToBeOverriten = sideNavBackgroundColor
  }

  if (!colorAreaToBeOverriten) return DEFAULT_OVERRIDES

  const colorOverride = `${colorAreaToBeOverriten}--color`

  const backgroundColorOverride = `${colorAreaToBeOverriten}--backgroundColor`

  const backgroundOverlayBeforeOverride = `${colorAreaToBeOverriten}--backgroundOverlayBefore`

  const backgroundOverlayAfterOverride = `${colorAreaToBeOverriten}--backgroundOverlayAfter`

  const backgroundColorGradientOverride = `${colorAreaToBeOverriten}--backgroundColorGradient`

  const fillColorOverride = `${colorAreaToBeOverriten}--fillColor`

  return {
    colorOverride,
    backgroundColorOverride,
    backgroundOverlayBeforeOverride,
    backgroundOverlayAfterOverride,
    backgroundColorGradientOverride,
    fillColorOverride,
  }
}
