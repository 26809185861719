import React from "react"

interface SVGComponent extends React.HTMLAttributes<HTMLOrSVGElement> {}

export const BackgroundImage = ({ ...rest }: SVGComponent) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1358.061"
    height="289.375"
    viewBox="0 0 1358.061 289.375"
    {...rest}
  >
    <path
      id="Path_15866"
      data-name="Path 15866"
      d="M1188.606,308.848C1047.96,305.127,923.686,226.4,787,281.075s-294.983-49.08-412.769,0-121.159,175.4-31.111,228.488,311.543-6.1,570.624-7.971,529.937,88.424,640.153,29.76,136.52-242.828-69.846-260.723S1329.252,312.568,1188.606,308.848Z"
      transform="translate(-280.196 -261.817)"
    />
  </svg>
)
