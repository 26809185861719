import React from "react"
import { useColorsOverride } from "hooks/color-override/useColorsOverride"
import { useBackgroundImage } from "hooks/useBackgroundImage"
import { GenerateVariationPrefixes } from "../_types/hero-classes-types"

const CONTAINER_SIZE = {
  default: "fr-container",
  large: "fr-container fr-container--large",
  medium: "fr-container fr-container--medium",
  small: "fr-container fr-container--small",
  none: "",
}

type ContainerSize = keyof typeof CONTAINER_SIZE

interface BannerWrapperProps {
  backgroundImageComponent?: React.ReactNode
  backgroundImageUrl?: string
  children: React.ReactNode
  containerSize?: ContainerSize
  contentClassName?: string
  prefix: GenerateVariationPrefixes
  wrapperClassName?: string
}

export const BannerWrapper = ({
  backgroundImageComponent,
  backgroundImageUrl,
  children,
  containerSize = "default",
  contentClassName = "",
  prefix,
  wrapperClassName = "",
}: BannerWrapperProps) => {
  const backgroundImageCSSVariable = useBackgroundImage({
    backgroundImageUrl,
    variable: "hero",
  })
  const colorAreaOverride = backgroundImageUrl ? "image-banner" : "text-banner"
  const { backgroundOverlayBeforeOverride } =
    useColorsOverride(colorAreaOverride)
  return (
    <section className={`${prefix} ${wrapperClassName}`}>
      {backgroundImageUrl && (
        <div
          className={`${prefix}__background-image ${backgroundOverlayBeforeOverride}`}
          style={backgroundImageCSSVariable}
        />
      )}
      <div className={`${CONTAINER_SIZE[containerSize]} ${contentClassName}`}>
        {children}
      </div>
      {backgroundImageComponent && backgroundImageComponent}
    </section>
  )
}
