import {
  BackgroundImageBreakpoint,
  getBackgroundImageUrlByBreakpoint,
} from "assets/helper/getBackgroundImageUrlByBreakpoint"

const DEFAULT_OPTIONS = {
  mobile: {
    width: 768,
  },
}

interface UseBackgroundImageParams {
  backgroundImageUrl?: string
  options?: BackgroundImageBreakpoint
  variable: string
}

export const useBackgroundImage = ({
  backgroundImageUrl,
  options,
  variable,
}: UseBackgroundImageParams) => {
  if (!backgroundImageUrl) return {}

  const customOptions = options ? { ...options } : {}

  const breakpoints = {
    ...DEFAULT_OPTIONS,
    ...customOptions,
  }

  const backgroundImageCSSVariable = getBackgroundImageUrlByBreakpoint({
    variableName: `${variable}-background-image`,
    imageUrl: backgroundImageUrl,
    breakpoints,
  })

  return backgroundImageCSSVariable
}
