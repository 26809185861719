import React from "react"
import { Banner } from "../HeroBanner-types"
import "components/hero/hero-common.module.scss"
import "./generic-hero.module.scss"
import {
  HeroContent,
  HeroContentBaseClassName,
} from "components/hero/HeroContent"
import { BannerWrapper } from "../BannerWrapper/BannerWrapper"
import { GenerateVariationPrefixes } from "../_types/hero-classes-types"
import { BackgroundImage } from "./assets/BackgroundImage"
import { useColorsOverride } from "hooks/color-override/useColorsOverride"

interface HeroProps {
  banner: Banner
}

export const GenericHero = ({ banner }: HeroProps) => {
  const { heading } = banner

  const prefix: GenerateVariationPrefixes = "fr-generic-hero"

  const wrapperClassName = `${prefix}--default-background-color`

  const bannerContentBaseClassName =
    `${prefix}__banner-content` as HeroContentBaseClassName

  const { fillColorOverride } = useColorsOverride("text-banner")

  return (
    <BannerWrapper
      contentClassName={bannerContentBaseClassName}
      prefix={prefix}
      wrapperClassName={wrapperClassName}
    >
      <BackgroundImage
        className={`${prefix}__background-image ${fillColorOverride}`}
      />
      <HeroContent
        baseClassName={bannerContentBaseClassName}
        heading={heading?.value}
      />
    </BannerWrapper>
  )
}
